import Title from "../Components/Title";
import banner4 from "../../img/menu/iaBg.png";
import seperator from "../../img/seperators/seperator.png";
import resp2 from "../../img/responsibility/resp2.png";
import resp3 from "../../img/responsibility/resp3.png";
import pop1 from "../../img/activities/pop1.jpg";
import pop2 from "../../img/activities/pop2.jpg";
import pop3 from "../../img/activities/pop3.jpg";
import pop4 from "../../img/activities/pop4.jpg";
const Activities = () => {
  return (
    <>
      <Title title={"Investment Activities"} titleBg={banner4} />
      <div className="container investmentActivitySection">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            Our activities revolve around investing in high potential and
            start-up companies in different sectors including food and
            beverages, fashion, real estate, media and advertising, governmental
            services, e-commerce, and retail.
            <br></br>
            <br></br>
            Our approach involves investing in high potential and start-up
            companies while maintaining operational control which allows for
            harmonized decision making all the while separating entities in
            their fields letting them shine in their market. EQ Holding holds a
            majority stake in the majority of investments, maintains operational
            control of the enterprise, and supports rapid decision making and
            adaptation to an ever-evolving environment. We can say that we are
            always looking through market trends and improving our services and
            products to match and take further steps ahead of the evolution of
            the current trends and environment. Therefore, our team is always
            aware and ready to counter-react to new opportunities in the market,
            leveraging the relationships of the experienced management as
            required.
            <br></br>
            <br></br>
            EQ Holding is continually monitoring long-term trends in the various
            areas in which it operates to identify emerging technologies that
            may have a positive impact on its business and development.
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <img src={seperator} alt="Seperator" className="seperator" />
          </div>
        </div>
        {/* <div className="row justify-content-center">
            <div className="col-12 col-md-9 col-lg-9 col-sm-12 ">
                <img className="investmentAct" src={act4} alt="Investment"/>
            </div>
            </div> */}

        <div className="row">
        <div className="col-lg-2 col-md-6  col-sm-6">
            <div >
              <div className="content-box">
              <img src={pop4} alt="Cop 2" className="investAct1"/>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6  col-sm-6">
            <div >
              <div className="content-box">
              <img src={resp2} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6  col-sm-6">
            <div className="item odd">
              <div className="content-box">
              <img src={pop2} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6  col-sm-6">
            <div >
              <div className="content-box">
              <img src={pop1} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6  col-sm-6">
            <div >
              <div className="content-box">
              <img src={resp3} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6  col-sm-6">
            <div >
              <div className="content-box">
              <img src={pop3} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Activities;
