import Title from "../Components/Title";
import Impact1 from "../../img/impacts/retail.png";
import reactState from "../../img/impacts/realstate.png";
import ecom from "../../img/impacts/commerce.png";
import media from "../../img/impacts/media.png";
import hospitality from "../../img/impacts/hospitality.png";
import banner2 from "../../img/menu/oiBg.png";
import seperator from "../../img/seperators/seperator.png";
import gov from "../../img/impacts/gov.png";

const Impacts = () => {
  return (
    <>
      <Title title={"Our Impact"} titleBg={banner2} />
      <section className="impactSection">
        <div className="container">
          <div className="row justify-content-center odd" id="fashion">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <img src={gov} alt="Impact 1" className="impact" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                EQ Holding Governmental Services aim to provide quality service
                to our clients, we are here to give modern, fast, and reliable
                service to each and every one. We envision making a strategic
                alliance with the government and the private sectors established
                in the UAE.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
          <div className="row justify-content-center odd" id="hospitality">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <img src={hospitality} alt="Impact 1" className="impact" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                EQ Holding Hospitality strives to provide a unique experience
                for today's discerning travelers. Our goal is to continuously
                push the boundaries by developing value segments in the target
                market. We continue to look for international partners who share
                our vision of creating value without compromising the customer
                experience.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>

          <div className="row justify-content-center even" id="reactstate">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <img src={reactState} alt="Impact 1" className="impact" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                EQ Holding's real estate business is at the forefront of
                developing, selling, and managing commercial, residential,
                tourism, and commercial real estate. EQ Holding contributes to
                the government's vision to develop its real estate industry and
                support the economic growth of the country, integrating master
                developers, and wealth managers in the real estate sector. It
                continues to create a wide range of real estate and real estate
                investment opportunities covering the emirates of various
                sectors.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>

          <div className="row justify-content-center" id="retail">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <img src={Impact1} alt="Impact 1" className="impact" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                The retail group is continually working closely with
                international suppliers to deliver the unique brands and fashion
                that customers will love in the GCC region. We offer exclusive
                brands to style-conscious men, women, and children, elevating
                the shopping experience to the next level.
                <br /> EQ Holding - Retail is perfectly positioned to support
                our leasing partners in their drive towards a prosperous future.
                The Group`s ambition is to bring world-renowned and exclusive
                brands to style-conscious customers throughout the Gulf region.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>

          <div className="row justify-content-center even" id="media">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <img src={media} alt="Impact 1" className="impact" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                Through major business districts managed by EQ Holding we are
                the leaders of media destinations by building a comprehensive
                platform and infrastructure for media companies of all sizes to
                thrive.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
          {/* <div className="row justify-content-center odd" id="fashion">
            <div className="col-lg-2">
              <img src={fashion} alt="Impact 1" />
            </div>
            <div className="col-lg-7">
              <p>
                The purpose of the EQ Holding Group is to provide world-renowned
                and upscale brands to style-conscious customers throughout the
                Gulf region. Customers serve men, women and children in addition
                to the range of household items. Aiming to be the best
                ambassador for international retailers in the MENA region, we
                continue to work closely with our suppliers to provide the
                unique brands and fashions our customers want to see in the MENA
                region.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div> */}

          <div className="row justify-content-center even" id="ecom">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <img src={ecom} alt="Impact 1" className="impact" />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                EQ Holding's e-commerce is dedicated to improving the business
                and creating world-class industry-leading companies. We work
                closely with our subsidiary companies to maximise growth and
                profitability, generate superior return on investment by
                improving the productivity, marketing competitiveness, and
                overall profitability of your company’s portfolio .
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Impacts;
