import Title from "../Components/Title";
import location from "../../img/icons/location.png";
import phone from "../../img/icons/phone.png";
import email from "../../img/icons/email.png";
import banner7 from "../../img/menu/cBg.png";
import seperator from "../../img/seperators/seperator.png";

import { RiDoubleQuotesL } from "react-icons/ri";
const Contact = () => {
  return (
    <>
      <Title title={"Contact Us"} titleBg={banner7} />
      <section className="contactForm">
        <div className="container">
          <div className="row contact-board-title justify-content-sm-center">
            <div className="col-12">
              <h4>CONTACT US</h4>
            </div>
            <div className="col-12 col-md-4 col-sm-12">
              <img src={location} alt="Location" />
              {/* <p>United Arab Emirates, Dubai, Deira, Al Khabaisi St., Nasser Bin Abdullatif Al Serkal Foundation, Office #201.</p> */}
              <p>
                KSA Address : Abdul Samad Alqurachi Head Office - Alhijra Dist -
                3th Ring Rood - Makka Al Mukarramah, Sa
              </p>
              <p>
                Dubai Address : Dubai-Sheikh Zayed Road - Al Safa - MSM2
                Building - Office 204 & 205
              </p>
            </div>
            <div className="col-12 col-md-4">
              <img src={email} alt="Email" />
              <p>info@eqholding.com</p>
            </div>
            <div className="col-12 col-md-4 col-sm-12">
              <img src={phone} alt="Phone Number" />
              <p>+971 4 232 8147</p>
            </div>
          </div>

          
        </div>
        <div className="col-12 col-md-12 col-sm-12">
            <img src={seperator} alt="Seperator" className="seperator" />
          </div>
        <div className="container-fluid form">
          <div className="row">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                  
                    <div className="contactText">
                    <div className="row">
                      <div className="col-12 contant-icon">
                        <RiDoubleQuotesL />
                      </div>
                    </div>
                      <h4>Have A Question For Us?</h4>
                      <p>
                        Message us here your concerns and we will reply as soon
                        as possible to you.
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="contactFormSection">
                      <input
                        placeholder="Type Email Here"
                        className="form-control mb-3"
                      />
                      <input
                        placeholder="Type Email Here"
                        className="form-control mb-3"
                      />
                      <textarea
                        placeholder="Type Message Here"
                        className="form-control mb-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="row">
                        <div className="col-lg-4">
                            <h3>Contact Information</h3>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </p>
                            <ul>
                                <li>
                                    <i><GoLocation /></i>
                                    <span>

                                    </span>
                                </li>
                                <li>
                                    <i><BsTelephone /></i>
                                    <span>
                                        + 971 12 123 456
                                    </span>
                                </li>
                                <li>
                                    <i><HiOutlineMail /></i>
                                    <span>
                                        <a href="mailto:info@eqholding.com">
                                            info@eqholding.com
                                        </a>
                                    </span>
                                </li>

                            </ul>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label>Name</label>
                                    <input className="form-control" placeholder="Name" />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <label>Email</label>
                                    <input className="form-control" placeholder="Email" />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label>Message</label>
                                    <textarea row="3" className="form-control"></textarea>
                                </div>
                                <div className="col-lg-12">
                                    <button className="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3608.417410679277!2d55.33658218383789!3d25.256540298461914!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5ce07eb71dd3%3A0x4e847d67f6b3b1f3!2sAl%20Serkal%20Building%20-%20Exit%2060%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2slb!4v1648931260308!5m2!1sen!2slb"height="450" style={{width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div> */}
    </>
  );
};
export default Contact;
