import logo from "../../../img/footerLogo.png";
import instaIcon from "../../../img/social/instaIcon.png";
import facebookIcon from "../../../img/social/facebookIcon.png";
import linkedInIcon from "../../../img/social/linkedIcon.png";
import twitterIcon from "../../../img/social/twitterIcon.png";
import youtubeIcon from "../../../img/social/youtubeIcon.png";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid footerBg">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <ul className="footerLeftMenu">
                      <li>
                        <a href="/Overview">Who We Are</a>
                      </li>
                      <li>
                        <a href="/Impact">Our Impact</a>
                      </li>
                      <li>
                        <a href="/Corporate-Responsibility">Responsibility</a>
                      </li>
                      <li>
                        <a href="/Subsidiary-Companies">
                          Subsidiaries & Related Companies
                        </a>
                      </li>
                      <li>
                        <a href="/Contact">Contact Us</a>
                      </li>
                      <li>
                        <a href="/business">Business Opportunities</a>
                      </li>
                      <li>
                        <a href="/career">Career Opportunities</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-4">
                    {/* <ul className="footerRightMenu">
                      <li>
                        <a href="#">Media Hub</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-10  copyright">
              <img src={logo} alt="Footer Logo" />
              <p>
                Copyright 2015 - {new Date().getFullYear() + " "} EQ Holding All Rights Reserved |{" "}
                <a href="/contact">Contact Us</a> 
                {/* |{" "}
                <a href="/privacy-policy">privacy policy</a> |{" "}
                <a href="/terms-conditions">Terms & conditions</a> |{" "}
                <a href="/cookie-policy">Cookie policy</a> | Sitemap */}
              </p>
            </div>
            <div className="col-2">
              <ul className="socialMedia">
                <li>
                  <a href="/" target="_blank">
                    <img src={instaIcon} alt="instaIcon" />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <img src={facebookIcon} alt="facebookIcon" />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <img src={twitterIcon} alt="twitterIcon" />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <img src={linkedInIcon} alt="linkedInIcon" />
                  </a>
                </li>
                <li>
                  <a href="/" target="_blank">
                    <img src={youtubeIcon} alt="youtubeIcon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
