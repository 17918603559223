import './../../Pages/Components/Title.css';
const Title = ({ title, titleBg }) => {
    return (
        <>
            <div className="titleSection">
                <img src={titleBg} alt={title}/>
                <div className="container">
                    <div className="col-lg-12">
                        <h2>
                            {title}
                        </h2>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Title;