import React, { useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Title from "../Components/Title";
import Form from "react-bootstrap/Form";
import bussinesslogo2 from "../../img/Business/businesslogo2.png";
import { toast } from "react-toastify";
// import axios from "axios";
// import { Global } from "../../Variables/Global";
const Business = () => {
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const [projectName, setProjectName] = useState("");
  const [activity, setActivity] = useState("");
  const [terms, setTerms] = useState(false);
  const [proposal, setProposal] = useState("");
  const [feasibility, setFeasibility] = useState("");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const handleProposalChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setProposal(e.target.files[0]);
    }
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFeasibility(e.target.files[0]);
    }
  };

  const Submit = async (e) => {
    e.preventDefault();
    if (
      projectName === "" ||
      activity === "" ||
      country === "" ||
      proposal === "" ||
      feasibility === ""
    ) {
      toast.error("Fill all the required fields");
    } else if (!terms) {
      toast.error("Accept terms and Condition to Submit");
    } else if (start > end) {
      toast.error("Investment needed is invalid");
    } else {
      // const formData = new FormData();
      // formData.append("files", proposal);
      // const response = await axios.post(
      //   Global.API_URL + "Others/UploadImage",
      //   formData,
      //   {
      //     headers: {
      //       Accept: "application/json",
            
      //     },
          
      //   }
      // );
      // if(response.ok){

      // }
    }
  };

  return (
    <>
      <Title title={"Business Opportunities"} titleBg={bussinesslogo2} />
      <section className="BusinessForm">
        <div className="container">
          <div className="row">
            {/* <div className="col-12">
            <h4>CONTACT US</h4>
          </div> */}
            {/* <div className="col-12 col-md-4">
           
            <p>Dubai Address : Dubai-Sheikh Zayed Road - Al Safa - MSM2 Building - Office 204 & 205</p>
            <p>KSA Address : Abdul Samad Alqurachi Head Office - Alhijra Dist - 3th Ring Rood - Makka Al Mukarramah, Sa</p>
          </div> */}
            {/* <div className="col-12 col-md-4">
         
            <p>info@eqholding.com</p>
          </div> */}
            {/* <div className="col-12 col-md-4">
         
            <p>+971 4 232 8147</p>
          </div> */}
          </div>
        </div>
        <div className="container-fluid form ">
          <div className="row ">
            <div className="col-12">
              <div className="container">
                <form onSubmit={(e) => Submit(e)}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="BusinessFormSection">
                        <input
                          placeholder="Name Of Project"
                          className="form-control mb-3"
                          maxLength="50"
                          value={projectName}
                          onChange={(e) => setProjectName(e.target.value)}
                        />
                        <label className="required">*</label>
                        <input
                          placeholder=" Activity Type"
                          className="form-control mb-3"
                          maxLength="100"
                          value={activity}
                          onChange={(e) => setActivity(e.target.value)}
                        />
                        <label className="required">*</label>

                        <div className="row">
                          <div className="col-lg-6">
                            <CountryDropdown
                              className="form-control mb-3"
                              value={country}
                              onChange={(e) => setCountry(e)}
                            />
                          </div>
                          <div className="col-lg-6">
                            <RegionDropdown
                              className="form-control mb-3"
                              country={country}
                              value={region}
                              onChange={(e) => setRegion(e)}
                              disabled={country === ""}
                              blankOptionLabel="Select Region"
                            />
                            <span className="required ">*</span>
                          </div>
                        </div>
                      </div>
                      <div className="BusinessText">
                        <h4>Terms and Conditions / Privacy Policy: </h4>
                        <p>
                          Your project is in good hands and will not be shared
                          with anyone, if your idea matches our activity we will
                          contact you
                        </p>
                        <input
                          type="checkbox"
                          value={terms}
                          onChange={(e) => setTerms(e.target.checked)}
                        />{" "}
                        <span>
                          I Agree to the{" "}
                          <a href="/terms-conditions">Terms and Conditions</a>{" "}
                          <span className="require">*</span>
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="BusinessFormSection">
                        <div className="row">
                          <div className="col-lg-12 mb-3 ">
                            <div className="input-group custom-file-button">
                              <label
                                className="input-group-text"
                                htmlFor="proposal"
                              >
                                Upload Proposal{"  "}
                                <label className="require">*</label>
                              </label>
                              <Form.Control
                                type="file"
                                className="form-control"
                                id="proposal"
                                onChange={handleProposalChange}
                                accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              ></Form.Control>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12 mb-3 ">
                            <div className="input-group custom-file-button">
                              <label
                                className="input-group-text"
                                htmlFor="inputGroupFile"
                              >
                                Upload Feasibility Study{"  "}
                                <label className="require">*</label>
                              </label>
                              <Form.Control
                                type="file"
                                className="form-control"
                                id="inputGroupFile"
                                onChange={handleFileChange}
                                accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              ></Form.Control>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <label className=" range mb-3">
                            Investment needed{" "}
                            <label className="require">*</label>
                          </label>
                          <div className="col-lg-6 range">
                            <label className="p-2" htmlFor="Range">
                              Start
                            </label>
                            <input
                              placeholder=""
                              className="form-control mb-3"
                              type="number"
                              min={0}
                              value={start}
                              onChange={(e) =>
                                setStart(parseFloat(e.target.value))
                              }
                            />{" "}
                          </div>

                          <div className="col-lg-6 range">
                            <label className="p-2" htmlFor="Range">
                              End
                            </label>
                            <input
                              placeholder=""
                              className="form-control mb-3"
                              type="number"
                              id="Range"
                              min={0}
                              value={end}
                              onChange={(e) =>
                                setEnd(parseFloat(e.target.value))
                              }
                              // disabled={start > end}
                              // inable={start <= end}
                            />{" "}
                          </div>
                        </div>

                        <button className="formbold-submit"> Submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Business;
