import Title from "../Components/Title";
import { GrFacebook, GrInstagram, GrLinkedin } from "react-icons/gr";
// import banner4 from "../../img/banner4.png";
import company from "../../img/company/company.png";
import company1 from "../../img/company/company1.png";
import company2 from "../../img/company/company2.png";
import company3 from "../../img/company/company3.png";
import company4 from "../../img/company/company4.png";
import company5 from "../../img/company/company5.png";
import company6 from "../../img/company/company6.png";
import company8 from "../../img/company/company8.png";
import company9 from "../../img/company/company9.png";
import subsidarysecond from "../../img/subsidary/subsidarysecond.png";

import banner4 from "../../img/banner5eq.png"

import seperator from "../../img/seperators/seperator.png";
const Companies = () => {
  return (
    <>
      <Title title={"Subsidiary Companies"} titleBg={banner4} />
      <section className="secCompanies">
        <div className="container">
          {/* <div className="row">
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(1);
                  scrollDownToId();
                }}
              >
                <img src={company} alt="MR1" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(2);
                  scrollDownToId();
                }}
              >
                <img src={company1} alt="Vision & More" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(3);
                  scrollDownToId();
                }}
              >
                <img src={company2} alt="Popcity" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(4);
                  scrollDownToId();
                }}
              >
                <img src={company3} alt="Platinum Apex" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(5);
                  scrollDownToId();
                }}
              >
                <img src={company4} alt="EQC Services" />
              </button>
            </div>
          </div> */}
          {/* <div className="row mt-89">
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(6);
                  scrollDownToId();
                }}
              >
                <img src={company5} alt="The Meating" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(7);
                  scrollDownToId();
                }}
              >
                <img src={company6} alt="EQ Real EState" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(8);
                  scrollDownToId();
                }}
              >
                <img src={subsidarysecond} alt="Bahia Khanoum " />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(9);
                  scrollDownToId();
                }}
              >
                <img src={company8} alt="The Meat City" />
              </button>
            </div>
            <div className="col">
              <button
                onClick={() => {
                  setCompanyId(10);
                  scrollDownToId();
                }}
              >
                <img src={company9} alt="JA Square" />
              </button>
            </div>
          </div> */}

          <div className="row justify-content-center singleCompany">
            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company4} alt="EQC" />
                </span>
                <span className="companyName">EQC Services</span>
              </div>
              <div className="content">
                <p>
                  We aim to provide quality service to our clients, we are here
                  to give modern, fast, and reliable service to each and every
                  one. We envision making a strategic alliance with the
                  government and the private sectors established in the UAE.
                </p>
                <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="https://www.facebook.com/eqc.rasalkhor/">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/tasheel.rasalkhor.eqc/">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company6} alt="EQ Real Estate" />
                </span>
                <span className="companyName">EQ Real Estate</span>
              </div>
              <div className="content">
                <p>
                  We are a real estate industry pioneering in purchasing raw
                  land constructing and renovating buildings to create and add
                  value to the future homes of our end users. We can facilitate
                  your transactions to help you achieve the purchase and sale
                  with the best possible terms{" "}
                </p>
                {/* <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company9} alt="JA Square" />
                </span>
                <span className="companyName">JA Square</span>
              </div>
              <div className="content">
                <p>
                  Ja-Square is a French telecommunication, technology & IoT
                  company, headquartered in Lille, with an outstanding presence
                  worldwide through its social representatives & distributors
                  across the United States, Europe, Australia, the Middle East,
                  and Africa.
                  <br /> Ja-Square is focused on developing innovative solutions
                  that cater to the private residential sector, as well as the
                  public telecommunications operator’s sector.
                </p>
                <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="https://www.facebook.com/jasquare1/">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/ja_square1/">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/ja-square">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company1} alt="Vision & More" />
                </span>
                <span className="companyName">Vision & More</span>
              </div>
              <div className="content">
                <p>
                  At Vision & More, we strive for success. We launch businesses
                  that would create legacies. Our comprehensive services enable
                  us to deliver 360-degree solutions. We materialise, nurture,
                  and help grow your businesses.
                </p>
                <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="https://www.facebook.com/visionandmoreintl/">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/visionandmoreintl/?igshid=v0nh28j654oi">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://lb.linkedin.com/company/vision-more?trk=similar-pages">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company2} alt="PopCity" />
                </span>
                <span className="companyName">Pop City</span>
              </div>
              <div className="content">
                <p>
                  We are the home of handcrafted and delicious pops that aim to
                  take traditional and innovative ideas and have them come
                  together in magical collaboration. Not just unique pops but we
                  focus on the excitement to celebrate amazing experiences.
                </p>
                <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="https://www.facebook.com/Popcity.UAE">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/popcity.uae/">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/popcityicecream/">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company5} alt="The Meating" />
                </span>
                <span className="companyName">The Meating</span>
              </div>
              <div className="content">
                <p>
                  The Meating is a dine-in concept based in Makkah and plans to
                  expand in Saudi Arabia as well as regional and international
                  markets. It aims to celebrate the meeting of good high-quality
                  food with good ambiance and ambience
                </p>
                {/* <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="title">
                <span className="companyImg">
                  <img src={subsidarysecond} alt="Bahia Khanoum" />
                </span>
                <span className="companyName">Bahia Khanoum</span>
              </div>
              <div className="content">
                <p>
                  Bahia khanoum serves great home-style Lebanese food in
                  addition to a collection of international & regional cuisine.
                  Bahia khanoum specialties, daily breakfast and lunch buffets,
                  & the plat du jour. Not only we offer the finest traditional
                  Lebanese cuisine as you know it, we also offer specialty
                  platters, on a daily basis, that you’re not likely to easily
                  find elsewhere. Bahia khanoum also offers a wide selection of
                  delicious sandwiches, prepared with the finest ingredients.
                </p>
                <ul className="col-lg-12 col-md-12 sub-links">
                  {/* <li>
                    <a href="">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.instagram.com/bahiakhanoum/">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company8} alt="The Meat City" />
                </span>
                <span className="companyName">The Meat City</span>
              </div>
              <div className="content">
                <p>
                  A Levantine eatery serving authentic dishes, both traditional
                  and modern, in a contemporary setting. MC draws inspiration
                  from the region's rich and luscious cuisine along with its
                  hospitable nature with dishes illustrating the ancient
                  Lebanese, Syrian, and Palestinian kitchens. MC bridges the gap
                  between authenticity and modernity, preserving ancient
                  traditions while pursuing innovative techniques.
                </p>
                <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="https://www.facebook.com/The-Meat-City-110042014963164">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 ">
              <div className="title">
                <span className="companyImg">
                  <img src={company} alt="MR1" />
                </span>
                <span className="companyName">MR1</span>
              </div>
              <div className="content">
                <p>No Content For This Subsidiary</p>
                {/* <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="title">
                <span className="companyImg">
                  <img src={company3} alt="Platinum Apex" />
                </span>
                <span className="companyName">Platinum Apex</span>
              </div>
              <div className="content">
                <p>No Content For This Subsidiary</p>
                {/* <ul className="col-lg-12 col-md-12 sub-links">
                  <li>
                    <a href="">
                      <i>
                        <GrFacebook />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrInstagram />
                      </i>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i>
                        <GrLinkedin />
                      </i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
        </div>
      </section>
      {/* <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-4">
                                    <img src={compPop} alt="Pop City" />
                                </div>
                                <div className="col-lg-8">
                                    <h5>Pop City</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <ul>
                                        <li>
                                            <a href="/">
                                                <i><GrFacebook /></i>
                                            </a>

                                        </li>
                                        <li>
                                            <a href="/">
                                                <i><GrInstagram /></i>
                                            </a>

                                        </li>
                                        <li>
                                            <a href="/">
                                                <i><GrLinkedin /></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-lg-4">
                                    <img src={compMeatCity} alt="The Meat City" />
                                </div>
                                <div className="col-lg-8">
                                    <h5>The Meat City</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <ul>
                                        <li>
                                            <a href="/">
                                                <i><GrFacebook /></i>
                                            </a>

                                        </li>
                                        <li>
                                            <a href="/">
                                                <i><GrInstagram /></i>
                                            </a>

                                        </li>
                                        <li>
                                            <a href="/">
                                                <i><GrLinkedin /></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
    </>
  );
};
export default Companies;
