import company1 from "../../../img/company1.png";
import company2 from "../../../img/company2.png";
import company3 from "../../../img/company3.png";
import { Carousel } from "3d-react-carousal";

const Overview = () => {
  let slides = [
    <img src={company1} alt="1" />,
    <img src={company2} alt="2" />,
    <img src={company3} alt="3" />,
  ];
  return (
    <>
    <div className="row">
       <div className="col-lg-12 col-md-12 titleSection1">
     
       {/* <img src={companyoverviewbackground}  alt="overview" className=" titleSection1"/> */}
     
    
      {/* <Title title={""} titleBg={companyoverviewbackground} className="titleSection1 mt-40" /> */}
        </div>
    </div>
      <div className="overviewSec">
        <div className="container">
          <div className="row justify-content-center">
          {/* <div className="col-lg-12 col-md-12 titleSection1">
              <img src={companyoverviewbackground} alt="overview" />
              </div> */}
            <div className="col-lg-6 col-sm-12 left-item">
                <div>
              <b>“Ehsan Al Qurashi Holding (EQ Holding)</b> has been established in
              2021 initially upon the realization that developing world-class
              concepts require constant innovation & development. With a
              commitment to enhancing the economic growth of the MENA region, EQ
              Holding is aiming for success by offering key solutions in
              multiple sectors and upholding long-term values for all its
              integrated subsidiaries. <br></br><br></br> EQ Holding has now established itself
              with a diverse portfolio of projects including in food and
              beverage, fashion, real estate, media and advertising, e-commerce,
              retail sectors, and governmental services amongst many others. 
              <br></br><br></br>EQ
              Holding supplies several common services to its subsidiaries
              through a large network of strategic alliances. Along with our
              stakeholders and associates, we look towards the future with
              confidence in accomplishing more monumental achievements. This has
              always been our belief!”
              </div>
            </div>
            <div className="col-lg-1">
                <br></br>
            </div>
            <div className="col-lg-4 col-sm-12 right-item">
              <Carousel slides={slides} autoplay={true} interval={5000} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Overview;
