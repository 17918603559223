import OwlCarousel from "react-owl-carousel";


// import banner1 from "../../../img/header/backgroundfirst.png";
// import banner2 from "../../../img/header/second.png";
// import banner3 from "../../../img/slider3.png";
// import banner4 from "../../../img/slider4.png";
// import banner5 from "../../../img/slider5.png";

import banner1 from "../../../img/banner-1.jpg"
import banner2 from "../../../img/banner-3.jpg";
import banner3 from "../../../img/banner-2.jpg";
import banner4 from "../../../img/banner-5.jpg";
import banner5 from "../../../img/banner-6.jpg";

// import ceo from "../../../img/anPic.png";
import CEOlogo from "../../../img/CEO/CEOlogo.png";

import OddIcon from "../../../img/eq-icon-odd.png";
import vmlogo from "../../../img/vmlogo.png";
// import cop1 from "../../../img/cop1.png";
// import cop2 from "../../../img/cop2.png";
// import cop3 from "../../../img/cop3.png";
import resp1 from "../../../img/responsibility/resp1.png";
import resp2 from "../../../img/responsibility/resp2.png";
import resp3 from "../../../img/responsibility/resp3.png";
import logo from "../../../img/logo.png";

import compPopCity from "../../../img/company/company2.png";
import compMeatCity from "../../../img/company/company1.png";
import compReal from "../../../img/company/company6.png";
import compEQC from "../../../img/company/company4.png";
import compPlat from "../../../img/company/company3.png";
import compMr from "../../../img/company/company.png";
import compMeating from "../../../img/company/company5.png";
import subsidaryfirst from "../../../img/subsidary/subsidaryfirst.png";
import compJaSquare from "../../../img/company/company8.png";
import company9 from "../../../img/company/company9.png";
// import company1 from "../../../img/company/company1.png";

import seperator from "../../../img/seperators/seperator.png";

import { CgArrowLongRight } from "react-icons/cg";
import { useEffect, useState } from "react";
import { Carousel } from "3d-react-carousal";
import company1 from "../../../img/company1.png";
import company2 from "../../../img/company2.png";
import company3 from "../../../img/company3.png";
import { FaMixcloud } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { GiKnifeFork } from "react-icons/gi";
import { BsCameraReels } from "react-icons/bs";
import { MdMiscellaneousServices } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";

// import iconone from "../../../img/OurImpact/iconone.png";
// import icontwo from "../../../img/OurImpact/icontwo.png";
// import iconthree from "../../../img/OurImpact/iconthree.png";
// import iconfour from "../../../img/OurImpact/iconfour.png";
// import iconfive from "../../../img/OurImpact/iconfive.png";
// import iconsix from "../../../img/OurImpact/iconsix.png";

const Home = () => {
  const [vmTitle, setVMTitle] = useState();
  const [vmText, setVMText] = useState();
  const [vmActive, setVMActive] = useState(1);

  let slides = [
    <img src={company1} alt="1" />,
    <img src={company2} alt="2" />,
    <img src={company3} alt="3" />,
  ];

  const changeText = (value) => {
    switch (value) {
      case 2:
        setVMTitle("Our Vision");
        setVMText(
          "Leading subsidiaries into global success and prosperity for years to come and providing one-of-a-kind services for all clients and business partners."
        );
        setVMActive(2);
        return;
      case 3:
        setVMTitle("Our Core Values");
        setVMText(
          "Our corporate culture and values reflect in our main guidelines and create a baseline for all employees to develop their performance."
        );
        setVMActive(3);
        return;
      default:
        setVMTitle("Our Mission");
        setVMText(
          "To ultimately maximize targeted investments and increase shareholders’ capital and value while maintaining exceptional partnerships."
        );
        setVMActive(1);
        return;
    }
  };
  useEffect(() => {
    changeText(1);
  }, []);
  return (
    <>
      <div className="slider-area">
        <OwlCarousel
          className="owl-theme main-slider"
          margin={10}
          items={1}
          autoplay
        >
          <div className="item">
            <img src={banner1} alt="Banner 1" height="750px" />
          </div>
          <div className="item">
            <img src={banner2} alt="Banner 2" height="750px" />
          </div>
          <div className="item">
            <img src={banner3} alt="Banner 3" height="750px"/>
          </div>
          <div className="item">
            <img src={banner4} alt="Banner 4"  height="750px"/>
          </div>
          <div className="item">
            <img src={banner5} alt="Banner 5" height="750px" />
          </div>
        </OwlCarousel>
      </div>
      <div className="company-area">
        <div className="no-empty-area">
          <img src={OddIcon} alt="Even Logo" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-box">
                <h2>Company Overview</h2>
                <p>
                  Ehsan Al Qurashi Holding (EQ Holding) has been established in
                  2021 initially upon the realization that developing
                  world-class concepts require constant innovation an
                  development.
                </p>
                <a href="/Overview">
                  Read More{" "}
                  <i>
                    <CgArrowLongRight />
                  </i>
                </a>
              </div>
              <img src={logo} alt="overview logo" className="overviewLogo" />
            </div>
            <div className="col-lg-6 img-area">
              <Carousel slides={slides} autoplay={true} interval={5000} />
            </div>
          </div>
        </div>
      </div>
      <div className="about-section">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-lg-4 text-center">
              <h2>Our Impact</h2>
            </div>
            <div className="col-lg-10 text-center">
              <p>
                EQ Holding has played an instrumental role in diversifying
                Dubai's economy. Collectively, our companies represent our
                involvement across 10 sectors, continually driving support and
                growth of a sustainable, innovation-led economy in line with the
                UAE government’s vision.
              </p>
            </div>
          </div>
          <div className="row impacts">
            <div className="col-lg-2 col-md-4 col-sm-4">
              <a href="/impact#retail">
                <div className="about-item">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <FaMixcloud />
                      <p className="impact-p"> Retail</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <a href="/impact#ecom">
                <div className="about-item">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <IoHome />
                      <p className="impact-p"> REAL ESTATE</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <a href="/impact#hospitality">
                <div className="about-item">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <GiKnifeFork />
                      <p className="impact-p"> Hospitality</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <a href="/impact#media">
                <div className="about-item">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <BsCameraReels />
                      <p className="impact-p">Media & Advertisment</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <a href="/impact#fashiom">
                <div className="about-item">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <FiShoppingCart />
                      <p className="impact-p">E-COMMERCE</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4">
              <a href="/impact#ecom">
                <div className="about-item">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <MdMiscellaneousServices />
                      <p className="impact-p">GOVERNMENTAL SERVICES</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          {/* <div className="row impacts">
            <div className="col-lg-2 col-md-4">
              <a href="/impact#retail">
                <div className="about-item1">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <img src={iconone} alt="Retail" />
                      <p className="impact-p1"> Retail</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-lg-2 col-md-4">
              <a href="/impact#retail">
                <div className="about-item1">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <img src={icontwo} alt="REAL ESTATE" />
                      <p className="impact-p1"> REAL ESTATE</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4">
              <a href="/impact#retail">
                <div className="about-item1">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <img src={iconthree} alt="Hospitality" />
                      <p className="impact-p1"> Hospitality</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4">
              <a href="/impact#retail">
                <div className="about-item1">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <img src={iconfour} alt="About 1" />
                      <p className="impact-p1">Media & Advertisment</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4">
              <a href="/impact#retail">
                <div className="about-item1">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <img src={iconfive} alt="About 1" />
                      <p className="impact-p1">FASHION</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-2 col-md-4">
              <a href="/impact#retail">
                <div className="about-item1">
                  <div className="row">
                    <div className="col-12 impact-icon">
                      <img src={iconsix} alt="About 1" />
                      <p className="impact-p1">GOVERNMENTAL SERVICES</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div> */}
          <div className="row">
            <div className="col-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
        </div>
      </div>

      <div className="secBoard">
        <div className="container-fluid">
          {/* <div className="row justify-content-center">
            <div className="col-md-12"> */}
          <div className="row justify-content-md-center board-title">
            <div className="col-lg-12">
              <h3>Board Of Directors & Key Figures</h3>
            </div>
          </div>
          <div className="row justify-content-md-center board-title">
            <div className="col-lg-4 text-center">
              <h5>Chairman</h5>
              <p>Mr. Ehsan Abdulsamad Al Qurshi</p>
            </div>
            <div className="col-lg-4 text-center">
              <h5>Vice Chairman</h5>
              <p>Mr. Abdulsamad Ehsan Al Qurashi</p>
            </div>
          </div>
          <div className="row justify-content-md-center board-title">
            <h5>Board of Directors</h5>
            <div className="col-lg-3  text-center">
              {/* <h5> Board of Directors</h5> */}
              <p>Mr. Abdulrahman Ehsan Al Qurashi </p>
            </div>
            <div className="col-lg-3 text-center">
              {/* <h5>Board of Directors</h5> */}
              <p>Eng. Abrahem Ehsan Al Qurashi</p>
            </div>
            <div className="col-lg-3 text-center">
              {/* <h5>Board of Directors</h5> */}
              <p>Eng. Abdullah Ehsan Al Qurashi</p>
            </div>
          </div>
          <div className="row justify-content-md-center board-title">
            <div className="col-lg-4 text-center">
              <h5>Chief Executive Officer</h5>
              <p>Dr. Ahmad Omar Noor</p>
            </div>
            <div className="col-lg-4 text-center">
              <h5>Deputy Chief Executive Officer</h5>
              <p>Mr. Taher Tayeb Anwahi</p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
        </div>
      </div>
      <div className="ceo-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-7">
              <img src={CEOlogo} alt="CEO" className="ceoLogo" />
            </div>
            <div className="col-lg-5 col-md-6 col-sm-5">
              <div className="content-box">
                <h3>Word From The CEO</h3>
                <p>
                  Our corporate approach towards combining a clear investment
                  strategy, high-quality performance to support and add value to
                  our portfolio by acquiring high potential and start-up
                  companies. We are ready to fund future projects in their
                  potential markets after careful study and analysis of
                  financial projections. Then introduce them to global markets
                  to improve business and ultimately multiply capital. Our
                  persistence and diligence towards sustaining honesty and
                  integrity throughout our business transactions mean that we
                  have succeeded as a whole with our investment partners.
                </p>
                <p>Dr Ahmad Noor</p>
                <a href="/CEO">
                  Discover More{" "}
                  <i>
                    <CgArrowLongRight />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vm-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-6 col-sm-6 vm-list">
              <div className="headers">
                <img src={vmlogo} alt="Vision & Mission Logo" />
                <ul>
                  <li className={`${vmActive === 1 ? "active" : ""}`}>
                    <a href="#Mission" onClick={() => changeText(1)}>
                      Mission
                    </a>
                  </li>
                  <li className={`${vmActive === 2 ? "active" : ""}`}>
                    <a href="#Vision" onClick={() => changeText(2)}>
                      Vision
                    </a>
                  </li>
                  <li className={`${vmActive === 3 ? "active" : ""}`}>
                    <a href="#Core" onClick={() => changeText(3)}>
                      Core Values
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-6">
              <div className="white-content-box">
                <h4>{vmTitle}</h4>
                <p>{vmText}</p>
                <a
                  // href={vmLink}
                  href="/Mission-Vision"
                >
                  Discover More
                  <i>
                    <CgArrowLongRight />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="corporate-area">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-lg-4">
              <h3 className="corporate-title">Corporate Responsibility</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-3  col-sm- d-lg-block d-sm-none">
              <div className="item odd">
                <img src={resp1} alt="Cop 1" />
              </div>
            </div>
            <div className="col-lg-2 col-md-6  col-sm-5">
              <div className="item even">
                <img src={resp2} alt="Cop 2" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6  col-sm-7">
              <div className="item odd">
                <div className="content-box">
                  {/* <h3>Our Partnership with Masafi</h3> */}
                  {/* <p>
                    At EQ Holding, we encourage and promote responsible living
                    and healthier lifestyles as part of our philosophy.
                  </p> */}
                  <p className="even">
                    We proactively inspire accountable residing via wholesome
                    lifestyles, entrepreneurial thinking, and contribution to
                    the network, even as worrying about the surroundings around
                    us. As an accountable company citizen, EQ Holding values the
                    well-being of our employees, with the aid of encouraging
                    innovative and progressive thinking, the destiny network,
                    and the surroundings, which shape the 4 middle pillars of
                    our Corporate Responsibility.
                    {/* <br/> Initially, to inspire
                    well-being and a more fit lifestyle for our colleagues, the
                    program has now extended to the broader Dubai network for a
                    fitter, healthier city, with EQ Holding actively organising,
                    partnering, and taking part in wellbeing sports, in addition
                    to tasks to increase progressive and entrepreneurial
                    abilities among employees. In addition, with our company`s
                    huge Intrapreneurs Challenge, we appear to decorate our
                    employees` entrepreneurial abilities to foster innovation
                    and innovative thinking. */}
                  </p>
                  <a href="/Corporate-Responsibility">
                    Discover More{" "}
                    <i>
                      <CgArrowLongRight />
                    </i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2  col-sm- d-lg-block d-sm-none">
              <div className="item even">
                <img src={resp3} alt="Cop 3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="companies-area">
        <div className="container">
          <div className="row">
            <div className="col-12 companies-title">
              <h2>SUBSIDIARY Companies</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <OwlCarousel className="owl-theme companyList" items={5} dots>
                <div className="item">
                  <img src={compEQC} alt="EQC" />
                </div>
                <div className="item">
                  <img src={compReal} alt="Real State" />
                </div>
                <div className="item">
                  <img src={company9} alt="JA Square" />
                </div>
                <div className="item">
                  <img src={compMeatCity} alt="vision and more" />
                </div>
                <div className="item">
                  <img src={compPopCity} alt="Pop City" />
                </div>
                <div className="item">
                  <img src={compMeating} alt="The Meating" />
                </div>
                <div className="item">
                  <img src={subsidaryfirst} alt="Bahia Khanoum " />
                </div>
                <div className="item">
                  <img src={compJaSquare} alt="Meat City" />
                </div>
                <div className="item">
                  <img src={compMr} alt="MR1" />
                </div>
                <div className="item">
                  <img src={compPlat} alt="Plat" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='companies-area'>
                <div className='container-fluid'>
                    <div className='row justify-content-md-center companies-title'>
                        <div className='col-lg-5'>
                            <h3>
                                Subsidiaries & Related Companies
                            </h3>
                        </div>
                        <div className='col-lg-12'>
                            <div className='container'>
                                <div className='row'>
                                    <OwlCarousel className='owl-theme companies-slider' margin={0} items={4}>
                                        <div className='item'>
                                            <a href="/">Pop City</a>
                                        </div>
                                        <div className='item'>
                                            <a href="/">The meating</a>
                                        </div>
                                        <div className='item'>
                                            <a href="/">Mission B</a>
                                        </div>
                                        <div className='item'>
                                            <a href="/">Platinum apex</a>
                                        </div>
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={EvenIcon} alt="Even Icon" className='even-icon' />
                </div>
            </div> */}
      {/* <div className='subsideries-area'>
                <img src={OddIcon} alt="AltIcon" className='oddIcon' />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 '>
                            <div className='left-area'>
                                <h4>
                                    SOCIAL MEDIA & OTHER CONTENT OF SUBSIDIARIES
                                </h4>
                            </div>
                        </div>
                        <div className='col-lg-6 right-area'>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/" >
                                            <img src={pcLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/">
                                            <img src={mcLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/" >
                                            <img src={mLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/" >
                                            <img src={vmmLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/">
                                            <img src={eqcLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/" >
                                            <img src={eqreLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/" >
                                            <img src={mrLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/">
                                            <img src={mbLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='subItem'>
                                        <a href="/" >
                                            <img src={paLogo} alt="Pop City Logo" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           */}
      {/* <div className='whats-area'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3>WHAT THEY SAY</h3>
                            <p>
                                EQ Holding has played an instrumental role in diversifying Dubai's economy. Collectively, our companies represent our involvement across 10 sectors, continually
                                driving support and growth of a sustainable, innovation-led economy in line with the UAE government’s vision.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className='blog-area'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <OwlCarousel className='owl-theme blog-slider' margin={10} items={2} dots>
                                <div className='item'>
                                    <div className='blog-item'>
                                        <p>
                                            EQ Holding has played an instrumental role in diversifying
                                            Dubai's economy. Collectively, our companies represent our
                                            involvement across 10 sectors, continually driving support
                                            and growth of a sustainable, innovation-led economy in
                                            line with the UAE government’s vision.
                                        </p>
                                        <div className='author'>
                                            <div className='image-box'>
                                                <img src={auth1} alt="m" />
                                            </div>
                                            <div className='content-box'>
                                                <h5>
                                                    Rashid Abdul Aziz
                                                </h5>
                                                <p>Marketing Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='blog-item'>
                                        <p>
                                            EQ Holding has played an instrumental role in diversifying
                                            Dubai's economy. Collectively, our companies represent our
                                            involvement across 10 sectors, continually driving support
                                            and growth of a sustainable, innovation-led economy in
                                            line with the UAE government’s vision.
                                        </p>
                                        <div className='author'>
                                            <div className='image-box'>
                                                <img src={auth2} alt="m" />
                                            </div>
                                            <div className='content-box'>
                                                <h5>
                                                    Rashid Abdul Aziz
                                                </h5>
                                                <p>Marketing Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='blog-item'>
                                        <p>
                                            EQ Holding has played an instrumental role in diversifying
                                            Dubai's economy. Collectively, our companies represent our
                                            involvement across 10 sectors, continually driving support
                                            and growth of a sustainable, innovation-led economy in
                                            line with the UAE government’s vision.
                                        </p>
                                        <div className='author'>
                                            <div className='image-box'>
                                                <img src={auth2} alt="m" />
                                            </div>
                                            <div className='content-box'>
                                                <h5>
                                                    Rashid Abdul Aziz
                                                </h5>
                                                <p>Marketing Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div className='blog-item'>
                                        <p>
                                            EQ Holding has played an instrumental role in diversifying
                                            Dubai's economy. Collectively, our companies represent our
                                            involvement across 10 sectors, continually driving support
                                            and growth of a sustainable, innovation-led economy in
                                            line with the UAE government’s vision.
                                        </p>
                                        <div className='author'>
                                            <div className='image-box'>
                                                <img src={auth2} alt="m" />
                                            </div>
                                            <div className='content-box'>
                                                <h5>
                                                    Rashid Abdul Aziz
                                                </h5>
                                                <p>Marketing Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};
export default Home;
