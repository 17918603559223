import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './Pages/Header/UI/Header';
import {
  BrowserRouter,
} from "react-router-dom";
import Footer from './Pages/Footer/UI/Footer';
import AppRoute from './Variables/AppRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <Header />
      <BrowserRouter>
        <AppRoute/>
      </BrowserRouter>
      <Footer/>

      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
    </>
  );
}

export default App;
