import Title from "../Components/Title";
import banner3 from "../../img/menu/mvBg.png";
import mission from "../../img/icons/mission.png";
import vision from "../../img/icons/vision.png";
import core from "../../img/icons/core.png";
import seperator from "../../img/seperators/seperator.png";
const Mission = () => {
  return (
    <>
      <Title title={"Mission & Vision"} titleBg={banner3} />
      <div className="secMissionVision">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 col-md-4">
              <img src={mission} alt="Mission" />
              <h3>Our Mission</h3>
              <p>
                To ultimately maximize targeted investments and increase
                shareholders’ capital and value while maintaining exceptional
                partnerships.
              </p>
            </div>
            <div className="col-lg-4 col-md-4">
              <img src={vision} alt="Vision" />
              <h3>Our Vision</h3>
              <p>
                Leading subsidiaries into global success and prosperity for
                years to come and providing one-of-a-kind services for all
                clients and business partners.
              </p>
            </div>
            <div className="col-lg-4 col-md-4">
              <img src={core} alt="Core Values" />
              <h3>Our Core Values</h3>
              <p>
                Our corporate culture and values reflect in our main guidelines
                and create a baseline for all employees to develop their
                performance.
              </p>
            </div>
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
};
export default Mission;
