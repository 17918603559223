import Title from "../Components/Title";
import banner6 from "../../img/menu/bodBg.png";
import seperator from "../../img/seperators/seperator.png";
const Board = () => {
  return (
    <>
      <Title title={"Board of Directors"} titleBg={banner6} />
      <div className="secBoard">
        <div className="container">
          {/* <div className="row justify-content-center">
            <div className="col-md-12"> */}
          <div className="row justify-content-md-center board-title">
            <div className="col-lg-12">
              <h3>Board Of Directors & Key Figures</h3>
            </div>
          </div>
          <div className="row justify-content-md-center board-title">
            <div className="col-lg-4 text-center">
              <h5>Chairman</h5>
              <p>Mr. Ehsan Abdulsamad Al Qurshi</p>
            </div>
            <div className="col-lg-4 text-center">
              <h5>Vice Chairman</h5>
              <p>Mr. Abdulsamad Ehsan Al Qurashi</p>
            </div>
          </div>
          <div className="row justify-content-md-center board-title">
            <h5>Board of Directors</h5>
            <div className="col-lg-3  text-center">
              {/* <h5> Board of Directors</h5> */}
              <p>Mr. Abdulrahman Ehsan Al Qurashi </p>
            </div>
            <div className="col-lg-3 text-center">
              {/* <h5>Board of Directors</h5> */}
              <p>Eng. Abrahem Ehsan Al Qurashi</p>
            </div>
            <div className="col-lg-3 text-center">
              {/* <h5>Board of Directors</h5> */}
              <p>Eng. Abdullah Ehsan Al Qurashi</p>
            </div>
          </div>
          <div className="row justify-content-md-center board-title">
            <div className="col-lg-4 text-center">
              <h5>Chief Executive Officer</h5>
              <p>Dr. Ahmad Omar Noor</p>
            </div>
            <div className="col-lg-4 text-center">
              <h5>Deputy Chief Executive Officer</h5>
              <p>Mr. Taher Tayeb Anwahi</p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-md-12">
              <img src={seperator} alt="Seperator" className="seperator" />
            </div>
          </div>
        </div>

        {/* </div> */}
      </div>
    </>
  );
};
export default Board;
