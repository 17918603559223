import React, { useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

import { Form } from "react-bootstrap";
import Title from "../Components/Title";
import careerlogo from "../../img/Career/careerlogo.png";
import seperator from "../../img/seperators/seperator.png";
import { toast } from "react-toastify";
const Career = () => {
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const [fullName, setFullName] = useState("");
  const [major, setMajor] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [cv, setCV] = useState("");
  const Submit = (e) => {
    e.preventDefault();
    if (
      fullName === "" ||
      country === "" ||
      major === "" ||
      qualifications === "" ||
      coverLetter === "" ||
      cv === ""
    ) {
      toast.error("Fill all the required fields");
    } else {
    }
  };
  const handleCV = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setCV(e.target.files[0]);
    }
  };
  const handleCoverletter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setCoverLetter(e.target.files[0]);
    }
  };

  return (
    <form onSubmit={(e) => Submit(e)}>
      <Title title={"Career Opportunities"} titleBg={careerlogo} />
      <section className="careerForm">
        <div className="container">
          <div className="row"></div>
        </div>
        <div className="container-fluid form ">
          <div className="row">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <div className="careerFormSection">
                      <div className="row">
                        <div className="col-lg-12">
                          <input
                            placeholder="Full Name Here"
                            className="form-control mb-3"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                          <label className="required">*</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <CountryDropdown
                            className="form-control mb-3"
                            value={country}
                            onChange={(val) => setCountry(val)}
                          />
                          <label className="required1">*</label>
                        </div>
                        <div className="col-lg-6">
                          <RegionDropdown
                            className="form-control mb-3"
                            country={country}
                            value={region}
                            onChange={(val) => setRegion(val)}
                            disabled={country === ""}
                            blankOptionLabel="Select Region"
                          />
                          <label className="required">*</label>
                        </div>
                      </div>

                      <Form.Select
                        className="form-control mb-3"
                        value={major}
                        onChange={(e) => setMajor(e.target.value)}
                      >
                        <option value="title" titleBg="transparent">
                          Major
                        </option>
                        <option value="volvo">Arts</option>
                        <option value="saab">Business</option>
                        <option value="fiat">Communications</option>
                        <option value="audi">Engineering</option>
                      </Form.Select>
                      <label className="required">*</label>

                      <Form.Select
                        className="form-control mb-3"
                        value={qualifications}
                        onChange={(e) => setQualifications(e.target.value)}
                      >
                        <option
                          value="High School Diploma"
                          titleBg="transparent"
                        >
                          High School Diploma
                        </option>
                        <option value="Bachelor">Bachelor</option>
                        <option value="Master">Master</option>
                        <option value="PhD">PhD</option>
                      </Form.Select>
                      <label className="required">*</label>

                      <div className="row">
                        <div className="col-lg-12 mb-3 ">
                          <div className="input-group custom-file-button">
                            <label className="input-group-text" for="CV">
                              Attach CV <label className="require">*</label>
                            </label>
                            <Form.Control
                              type="file"
                              className="form-control"
                              id="CV"
                              onChange={handleCV}
                              accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            ></Form.Control>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 mb-3 ">
                          <div className="input-group custom-file-button">
                            <label
                              className="input-group-text"
                              for="CoverLetter"
                            >
                              Attach Cover Letter
                            </label>
                            <Form.Control
                              type="file"
                              className="form-control"
                              id="CoverLetter"
                              onChange={handleCoverletter}
                              accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            ></Form.Control>
                          </div>
                        </div>
                      </div>

                      <button
                        className="formbold-submit"
                        // onClick={(e) => Submit(e)}
                      >
                        {" "}
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12 careerFormtext">
                    <div className="careerText">
                      <h4>Want A Great Oppurtunity?</h4>
                      <p>
                        Message us here your hiring information and your CV.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-md-12 ">
                    <img
                      src={seperator}
                      alt="Seperator"
                      className="seperator"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};

export default Career;
