import Title from "../Components/Title";
import banner5 from "../../img/menu/ceoBg.png";
import CEOlogo from "../../img/CEO/CEOlogo.png";
const CEO = () => {
  return (
    <>
      <Title title={""} titleBg={banner5} />
      <div className="secCEOMessage">
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-6">
              <img src={CEOlogo} alt="CEOImage" />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 col-sm-6 ceoWord">
              <h1>Word From The CEO</h1>
              <p>
                Our corporate approach towards combining a clear investment
                strategy, high-quality performance to support and add value to
                our portfolio by acquiring high potential and start-up
                companies. We are ready to fund future projects in their
                potential markets after careful study and analysis of financial
                projections. Then introduce them to global markets to improve
                business and ultimately multiply capital.
                <br></br> Our persistence and diligence towards sustaining
                honesty and integrity throughout our business transactions mean
                that we have succeeded as a whole with our investment partners.
              </p>
              <p>Dr Ahmad Noor</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CEO;
