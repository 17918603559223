import { Route, Routes } from "react-router-dom";
import Activities from "../Pages/Activities/Activities";
import Board from "../Pages/Board/Board";
import Business from "../Pages/Business/Business";
import Career from "../Pages/Career/Career";
import CEO from "../Pages/CEO/CEO";
import Companies from "../Pages/Companies/Companies";
import Overview from "../Pages/CompanyOverview/UI/Overview";
import Contact from "../Pages/Contact/Contact";
import Home from "../Pages/Home/UI/Home";
import Impacts from "../Pages/Impacts/Impacts";
import Mission from "../Pages/MissionVision/Mission";
import Responsibility from "../Pages/Responsibility/Responsibility";
const AppRoute = () =>{
    return (
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Overview" element={<Overview/>}></Route>
          <Route path="/Mission-Vision" element={<Mission/>}></Route>
          <Route path="/Board" element={<Board/>}></Route>
          <Route path="/CEO" element={<CEO/>}></Route>
          <Route path="/Impact" element={<Impacts/>}></Route>
          <Route path="/Contact" element={<Contact/>}></Route>
          <Route path="/Subsidiary-Companies" element={<Companies/>}></Route>
          <Route path="/Investment-Activities" element={<Activities/>}></Route>
          <Route path="/career" element={<Career/>}></Route>
          <Route path="/business" element={<Business/>}></Route>
          <Route path="/Corporate-Responsibility" element={<Responsibility/>}></Route>
        </Routes>
    )
}
export default AppRoute;