import React, { useState } from "react";
import { Button } from "react-bootstrap";

import logo from "../../../img/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import header from "../../../img/header/header.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { VscThreeBars } from "react-icons/vsc";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <header>
  
      <div className="Header-area">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="container-fluid">
              <div className="row ">
              <div className="col-sm-10 col-xs-3  d-xl-none  d-lg-none">
                <a href="/">

              
                    <img className="l-img" src={logo} alt="logo" />
                    </a>
                  </div>
                       
                <div className="col-sm-2  col-xs-3 h-side  d-xl-none d-lg-none">
                  <div className="container ">
                    <div className="row side1 p-2 ">
                      <div className="col-md-5  ">
                        <Button
                          variant="primary"
                          className="b-show"
                          onClick={handleShow}

                          // {...props}
                        >
                          {" "}
                          <VscThreeBars />{" "}
                        </Button>
                      </div>
                    </div>

                    <Offcanvas
                      show={show}
                      responsive="lg"
                      className="offcanvas-end"
                    >
                      <Offcanvas.Header
                        closeButton
                        className=""
                        id="offcanvasRightLabel"
                      >
                        <Offcanvas.Title>
                          {" "}
                          <label className="back" onClick={handleClose}>
                            <MdOutlineKeyboardArrowLeft /> Go Back
                          </label>
                        </Offcanvas.Title>
                      </Offcanvas.Header>
                      <div className="row ">
                        <div className="col-md-5">
                          <a className="" href="/" variant="link">
                            <img className="log-img" src={logo} alt="logo" />
                          </a>
                        </div>
                      </div>
                      <Offcanvas.Body>
                        <div className="row ">
                          <div className="col-12 log1-img">
                            {/* <img className="log1-img" src={Group114} /> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 ">
                            <a
                              className="canvas-link"
                              href="/Overview"
                              variant="link"
                            >
                              Company Overview
                            </a>
                            <a  href="/Overview">
                              <span className="circle"></span>
                            </a>
                            <div className="row justify-content-center ">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/Impact"
                              variant="link"
                            >
                              Our Impact
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/Board"
                              variant="link"
                            >
                              Board Of Directors
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/CEO"
                              variant="link"
                            >
                              CEO Message
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/Mission-Vision"
                              variant="link"
                            >
                              Mission & Vision
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/Subsidiary-Companies"
                              variant="link"
                            >
                              Our Subsidiaries
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/business"
                              variant="link"
                            >
                              Business Opportunities
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <a
                              className="canvas-link"
                              href="/Investment-Activities"
                              variant="link"
                            >
                             Investment Activities
                            </a>
                            <span className="circle"></span>
                            <div className="row justify-content-center">
                              <div className="col-12 hr1-list  d-none d-md-block d-lg-none"></div>
                            </div>
                          </div>
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </div>{" "}

                   
                </div>
               </div>
               
                
                
               <div className="row ">
                <div className="col-12 col-md-12 d-none d-md-none d-lg-block d-sm-none ">
                  <Navbar  variant="light" className="main-header">
                    <Container>
                      <div className="row">
                        <div className=" col-lg-12 col-md-4 col-xs-6 ">
                          <Navbar.Brand href="/">
                            <img className="" src={header} alt="header" />
                          </Navbar.Brand>
                        </div>
                      
                    
                        <div className=" col-lg-12 col-md-8 col-xs-12">
                          <Nav className="me-auto">
                            <Nav.Link href="/Overview">
                              Company Overview{" "}
                            </Nav.Link>
                            <Nav.Link href="/Impact"> Our Impact</Nav.Link>

                            <Nav.Link href="/Board">
                              {" "}
                              Board Of Directors
                            </Nav.Link>
                            <Nav.Link href="/CEO"> CEO Message</Nav.Link>
                            <Nav.Link href="/Mission-Vision">
                              {" "}
                              Mission & Vision
                            </Nav.Link>
                            <Nav.Link href="/Subsidiary-Companies">
                              {" "}
                              Our Subsidiaries
                            </Nav.Link>
                            <Nav.Link href="/business">
                              {" "}
                              Business Opportunities
                            </Nav.Link>
                            <Nav.Link href="/Investment-Activities">
                              {" "}
                              Investment Activities
                            </Nav.Link>
                          </Nav>
                        </div>
                        </div>
                    </Container>
                  </Navbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
