import Title from "../Components/Title";
import banner4 from "../../img/menu/iaBg.png";
import seperator from "../../img/seperators/seperator.png";
import resp2 from "../../img/responsibility/resp2.png";
import resp3 from "../../img/responsibility/resp3.png";
import resp1 from "../../img/responsibility/resp1.png";
const Responsibility = () => {
  return (
    <>
      <Title title={"Corporate Responsibility"} titleBg={banner4} />
      <div className="container ResponsibilitySection">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            We proactively inspire accountable residing via wholesome
            lifestyles, entrepreneurial thinking, and contribution to the
            network, even as worrying about the surroundings around us. As an
            accountable company citizen, EQ Holding values the well-being of our
            employees, with the aid of encouraging innovative and progressive
            thinking, the destiny network, and the surroundings, which shape the
            4 middle pillars of our Corporate Responsibility.
            <br></br>
            <br></br>
            Initially, to inspire well-being and a more fit lifestyle for our
            colleagues, the program has now extended to the broader Dubai
            network for a fitter, healthier city, with EQ Holding actively
            organising, partnering, and taking part in wellbeing sports, in
            addition to tasks to increase progressive and entrepreneurial
            abilities among employees. In addition, with our company`s huge
            Intrapreneurs Challenge, we appear to decorate our employees`
            entrepreneurial abilities to foster innovation and innovative
            thinking.
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <img src={seperator} alt="Seperator" className="seperator" />
          </div>
        </div>
        {/* <div className="row justify-content-center">
            <div className="col-12 col-md-9 col-lg-9 col-sm-12 ">
                <img className="investmentAct" src={act4} alt="Investment"/>
            </div>
            </div> */}

        <div className="row">
          <div className="col-lg-4 col-md-6  col-sm-6">
            <div>
              <div className="content-box">
                <img src={resp1} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  col-sm-6">
            <div>
              <div className="content-box">
                <img src={resp2} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  col-sm-6">
            <div>
              <div className="content-box">
                <img src={resp3} alt="Cop 2" className="investAct1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Responsibility;
